var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c("div", { staticClass: "user-message text-center" }, [
      _c("div", { staticClass: "d-flex align-center justify-center" }, [
        _c("div", { staticClass: "mew-heading-2" }, [
          _vm._v("Redirecting to app store"),
        ]),
        _c("span", { staticClass: "loading-dots text-left mew-heading-2" }, [
          _vm._v(" " + _vm._s(_vm.loadingDots) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mt-3 mx-auto", staticStyle: { "max-width": "400px" } },
        [
          _vm._v(
            " Click app store button, if you don't get redirected to app store automatically. "
          ),
        ]
      ),
      _c("div", { staticClass: "mt-10" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://itunes.apple.com/app/id1464614025",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "mr-1",
              attrs: {
                src: require("@/assets/images/icons/button-app-store.svg"),
                alt: "Play store",
                height: "35",
              },
            }),
          ]
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://play.google.com/store/apps/details?id=com.myetherwallet.mewwallet",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/button-play-store.svg"),
                alt: "App store",
                height: "35",
              },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }